body
{
	&.login
	{
		.login__panel
		{
			height: 100%;

			&>.row
			{
				height: 100%;
			}

			header
			{
				padding: 0 0 rem-calc(10);

				h1
				{
					margin: 0;
					font-size: rem-calc(35);
					text-align: center;
				}
			}

			.text__inputs
			{
				margin: rem-calc(20) auto;

				.field
				{
					margin: rem-calc(7) auto 0;

					input
					{
						max-width: 500px;
						width: 100%;
						height: 45px;
						padding-left: rem-calc(10);
					}
				}
			}

			.button__large__blue
			{
				margin-right: rem-calc(15);
			}
		}
	}
}