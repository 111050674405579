.tabs-title
{
	&>a
	{
		display: inline-block;
		padding-left: rem-calc(15);
		padding-right: rem-calc(15);
		text-decoration: underline;

		&:focus
		{
			background: none;
		}
	}

	&>.button__inline__block
	{
		height: rem-calc(30);
		margin-left: rem-calc(0);
		margin-right: rem-calc(15);
		padding: rem-calc(5) rem-calc(10);
		font-size: rem-calc(12);
		line-height: rem-calc(20);
	}

	&.is-active
	{
		background: lighten($black, 85%);

		&>a
		{
			background: none;

			&:focus
			{
				background: none;
			}
		}
	}
}