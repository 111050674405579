form
{
	&.edit__form
	{
		label
		{
			margin: 0 0 rem-calc(5);
			font-weight: 700;
			font-size: rem-calc(12);
		}

		input[type="text"],
		input[type="email"],
		input[type="password"],
		textarea,
		select
		{
			margin: 0;
			padding: rem-calc(4) rem-calc(5);
			border: 1px solid lighten($black, 80%);
			@include border-radius(3.01px);
			background: $white;
			box-shadow: inset 0 1px 2px rgba(0, 0, 0, .07);
			vertical-align: baseline;
			color: lighten($black, 20%);
			font-size: rem-calc(16);

			&:focus
			{
				border-color: $med-blue;
				box-shadow: inset 0 1px 2px rgba(0, 0, 0, .07);
			}

			@include placeholder
			{
				color: lighten($black, 60%);
			}

			&.input__field__inline
			{
				display: inline-block;
				height: auto;
				padding: 0 rem-calc(10);
				border: 1px solid lighten($black, 90%);
				@include border-left-radius(3px);
				@include border-top-radius(0);
				background: $white;
				font-size: rem-calc(12);
				line-height: rem-calc(40);
				vertical-align: middle;

				&:first-child
				{
					margin-right: -1px;
				}
			}
		}


		.check__list
		{
			list-style: none;
			margin: 0;
			padding: 0;

			li
			{
				&:before
				{
					margin-right: rem-calc(5);
					content: '\f0c8';
					color: lighten($black, 80%);
					font-family: 'FontAwesome';
					font-weight: 400;
				}

				&.selected
				{
					&:before
					{
						color: $black;
						content: '\f14a';
					}
				}

				font-weight: 400;
				cursor: pointer;
			}
		}

		/*input[type="checkbox"]
		{
			width: 0;
			height: 0;
			position: absolute;
			left: -9999px;
		}


		.checkbox
		{
			display: inline-block;
			width: rem-calc(25);
			height: rem-calc(25);
			font-size: rem-calc(20);

			&:after
			{
				content: '\f10c';
				font-family: 'FontAwesome';
			}
		}

		input[type="checkbox"]:checked ~ .checkbox 
		{
			&:after
			{
				content: '\f05d';
			}
		}*/


		.select
		{
			height: rem-calc(40);
			border: 1px solid lighten($black, 80%);
			@include border-radius(3.01px);
			box-shadow: inset 0 1px 2px rgba(0, 0, 0, .07);
			background: $white;
			overflow: hidden;
			position: relative;

			select
			{
				width: 120%;
				height: auto;
				margin: 0;
				padding: rem-calc(9) rem-calc(5);
				border: 0;
				background: transparent;
				-webkit-appearance: none;
				-moz-appearance: none;
				color: lighten($black, 20%);
				font-size: rem-calc(16);
				position: relative;
				z-index: 2;
			}

			.arrow
			{
				width: rem-calc(40);
				height: rem-calc(40);
				line-height: rem-calc(35);
				text-align: center;
				color: lighten($black, 60%);
				position: absolute;
				right: 0;
				top: 0;
				z-index: 1;
			}

			&:hover
			{
				border-color: $med-blue;
				box-shadow: inset 0 1px 2px rgba(0, 0, 0, .07);
			}
		}


		.form__row
		{
			margin: 0 0 rem-calc(10);

			&.bannerImage
			{
				label
				{
					font-weight: 400;
				}
			}

			&.half
			{
				.half__row		
				{
					@include flex-grid-row('collapse', 'expand');
				}
			}

			.form__col
			{
				@include flex-grid-column('expand', 0);

				&:first-child
				{
					padding-right: rem-calc(8);
				}

				&:last-child
				{
					padding-left: rem-calc(8);
				}
			}
		}


		.inline__block
		{
			label
			{
				display: inline-block;
				font-weight: 400;
			}
		}

		&.dropzone
		{
			min-height: 0;
			border: 2px dotted lighten($black, 85%);
			background: lighten($black, 98%);
		}
	}


	// Search bar
	&.admin__list__search
	{
		.input__field__inline
		{
			display: inline-block;
			height: auto;
			margin-bottom: 0;
			padding: 0 rem-calc(10);
			border: 1px solid lighten($black, 90%);
			@include border-radius(0);
			background: $white;
			box-shadow: none;
			font-size: rem-calc(12);
			line-height: rem-calc(40);
			vertical-align: middle;

			&:first-child
			{
				margin-right: -1px;
				@include border-left-radius(3px);
			}

			&.select
			{
				//height: rem-calc(40);
				//border: 1px solid lighten($black, 80%);
				//@include border-radius(3.01px);
				//box-shadow: inset 0 1px 2px rgba(0, 0, 0, .07);
				//background: $white;
				overflow: hidden;
				position: relative;

				select
				{
					width: 120%;
					height: auto;
					margin: 0;
					padding: rem-calc(9) rem-calc(5);
					border: 0;
					background: transparent;
					-webkit-appearance: none;
					-moz-appearance: none;
					font-size: rem-calc(12);
					position: relative;
					z-index: 2;

					&:focus
					{
						outline: none;
						box-shadow: none;
					}
				}

				.arrow
				{
					width: rem-calc(40);
					height: rem-calc(40);
					line-height: rem-calc(40);
					text-align: center;
					color: lighten($black, 60%);
					position: absolute;
					right: 0;
					top: 0;
					z-index: 1;
				}
			}
		}
	}
}


.display__lead__image
{
	flex: initial !important;
	width: rem-calc(200) !important;
	height: rem-calc(200);
	margin-right: rem-calc(30);
	border: 1px solid lighten($black, 80%);
	@include border-radius(3.01px);
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: $white;
	overflow: hidden;
	position: relative;
}


.form-error
{
	margin-top: rem-calc(5);
	margin-bottom: 0;
}


.image-uploader {
	.image-display {
		cursor: pointer;

		.remove-button {
			width: 100%;
		}
	}

	.dropzone {
		min-height: 0;
		border: 2px dotted lighten($black, 85%);
		background: lighten($black, 98%);
	}
}
.selectize-dropdown-content {
	max-height: 150px !important;
}
