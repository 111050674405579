@import "../foundation-sites/scss/foundation";

// Font Awesome
$fa-font-path: "../fonts/fontawesome/";
@import "../font-awesome/fontawesome";
@import "../font-awesome/fa-light";
@import "../font-awesome/fa-brands";
@import "../font-awesome/fa-regular";
@import "../font-awesome/fa-solid";


@include foundation-global-styles;
@include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

// Settings
@import "settings/settings";

// Globals
@import 'global/colors';
@import 'global/typography';
@import 'global/mixin';

// Base
@import 'base';

// Modules
@import 'modules/header';
@import 'modules/footer';

// Admin Templates
@import 'templates/login';

// Components
@import 'components/forms';
@import 'components/buttons';
@import 'components/nav';
@import 'components/lists';
@import 'components/slider';
@import 'components/reveal';
@import 'components/flash_notice';
@import 'components/tabs';
@import 'components/dialog';
@import "components/grid";