html,
body
{
	height: 100%;
}

body
{
	font-family: $body-font-family;
	overflow: hidden;
}


#page
{
	//@include flex-grid-row('collapse', 'expand');
	background: #f1f1f1;
	height: 100vh;
	overflow-y: scroll;


	#content
	{
		min-height: 100%;
		padding-left: rem-calc(304);

		.content__block
		{
			header
			{
				display: flex;
				padding: rem-calc(40) rem-calc(40) 0 rem-calc(40);
				@extend .align-middle;
			}

			.inner__content__block
			{
				padding: rem-calc(40) rem-calc(40) rem-calc(150);
			}
		}
	}
}


.row
{
	&.expand
	{
		@include flex-grid-row('collapse', 'expand');
	}
}