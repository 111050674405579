.table__list
{
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	border-top: 1px solid lighten($black, 90%);
	border-bottom: 1px solid lighten($black, 90%);
	background-color: $white;

	thead
	{
		border: none;
		background: none;

		tr
		{
			th
			{
				padding: rem-calc(5);
				text-transform: uppercase;
				font-weight: $body-font-weight-normal;
				font-size: rem-calc(12);

				&:first-child
				{
					padding-left: rem-calc(15);
				}

				&.last
				{
					width: rem-calc(250);
				}

				a
				{
					&:after
					{
						display: inline-block;
						margin-left: rem-calc(5);
						color: lighten($black, 80%);
						font-size: rem-calc(10);
						font-family: 'FontAwesome';
						content: '\f0dc';
					}

					&.order-asc
					{
						&:after
						{
							color: $light-blue;
							content: '\f0d8';
						}
					}

					&.order-desc
					{
						&:after
						{
							color: $light-blue;
							content: '\f0d7';
						}
					}
				}
			}
		}
	}

	tbody
	{
		border: none;
		background: none;

		tr
		{
			td
			{
				padding: rem-calc(5);
				border-top: 1px solid lighten($black, 90%);
				font-size: rem-calc(14);

				&:first-child
				{
					padding-left: rem-calc(15);
				}

				input[type="checkbox"]
				{
					margin: 0;
				}

				.button__remove
				{
					@extend .button__normal;
					height: rem-calc(30);
					margin-left: rem-calc(5);
					margin-right: rem-calc(5);
					padding: rem-calc(5) rem-calc(10);
					font-size: rem-calc(12);
					line-height: rem-calc(20);
				}
			}

			&:hover
			{
				td
				{
					background-color: lighten($black, 98%);
					box-shadow: lighten($black, 90%) 0 -1px 0 inset, lighten($black, 90%) 0 1px 0 inset;
				}
			}

			&:nth-child(even)
			{
				background-color: $white;
			}

			&.last
			{
				width: rem-calc(200);
			}
		}
	}
}



.admin__list__bar
{
	margin-bottom: rem-calc(25);
	padding: rem-calc(15);
	border-top: 1px solid lighten($black, 90%);
	border-bottom: 1px solid lighten($black, 90%);
	background: $white;

	.grid
	{
		display: flex;


		&.spacer
		{
			margin-top: rem-calc(10);
		}

		.col
		{
			min-width: 0;
			display: flex;
			flex: 1 1 0%;
			@extend .align-middle;

			&.no-col
			{
				flex: none;
			}

			&.top
			{
				vertical-align: top;
				align-self: flex-start;
			}

			.title
			{
				display: flex;
				//align-items: flex-start;
				@extend .align-middle;
				text-transform: uppercase;
				font-weight: $body-font-weight-bold;
				font-size: rem-calc(14);

				&.results
				{
					text-transform: none;
					font-weight: $body-font-weight-normal;
					font-size: rem-calc(12);
				}

				.icon
				{
					flex-shrink: 0;

					span
					{
						width: rem-calc(35);
						height: rem-calc(35);
						margin-right: rem-calc(10);
						@include border-radius(3px);
						background: $black;
						color: $white;
						font-size: rem-calc(20);
						text-align: center;
						line-height: rem-calc(35);
					}
				}

				h1
				{
					flex: 1;
					margin: 0;
					min-width: 0;
				}
			}

			&.last
			{
				flex: none;
				align-self: flex-start;
			}

			// Search form
			form
			{
				font-size: 0;
				
				input[type="text"],
				select
				{
					width: rem-calc(150);
				}

				.inline__block
				{
					display: inline-block;
					//width: rem-calc(60);
					margin-left: -1px;
					padding: 0 rem-calc(10);
					border: 1px solid lighten($black, 90%);
					//@include border-left-radius(3px);
					//@include border-top-radius(0);
					background: $white;
					color: lighten($black, 60%);
					font-size: rem-calc(12);
					vertical-align: middle;

					label,
					input
					{
						display: inline-block;
						margin: 0;
						padding: 0;
						color: lighten($black, 60%);
						font-weight: 400;
						font-size: rem-calc(12);
						line-height: rem-calc(40);
						vertical-align: middle;
					}

					input
					{
						margin-left: rem-calc(10);
					}

					input[type="checkbox"]
					{
						width: auto;
						height: auto;
						position: relative;
						left: 0;
					}
				}
			}
		}
	}

	.list__menu
	{
		font-size: rem-calc(14);
	}
}


.tiles
{
	@include flex-grid-column(100%, 0);
	list-style: none;
	margin: 0 rem-calc(-15);
	padding: 0;

	li
	{
		display: inline-block;
		width: calc((100%) / 6);
		margin: 0 rem-calc(15) rem-calc(30);
		padding: rem-calc(20);
		border: 2px dotted lighten($black, 90%);
		background: lighten($black, 98%);
	}
}


#sortable
{
	@extend .row;
	@include flex-grid-layout(1, '#sortable');
	max-width: 100% !important;
	margin-top: rem-calc(30);
	margin-bottom: rem-calc(30);
	margin-left: rem-calc(-15);
	margin-right: rem-calc(-15);

	div
	{
		//@include flex-grid-column(33.333333%, 60);
		//margin-right: 0;
		margin-bottom: rem-calc(15);

		.display__lead__image
		{
			width: 100% !important;
		}
	}
}


.missing
{
	padding: 120px;
	background: darken($white, 10%);
	text-align: center;

	.button__large__blue
	{
		&:hover
		{
			color: $white;
		}
	}
}

.ui-state-highlight
{
	border: none !important;
	background: #ddd !important;
	width: 25%;
	height: auto;
}