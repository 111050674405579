#slider
{
	background: $white;
	padding: rem-calc(30);

	h2
	{
		font-size: rem-calc(24);
	}

	.close__button
	{
		color: $med-blue;
		font-size: rem-calc(22);
		position: absolute;
		right: rem-calc(30);
		top: rem-calc(30);
		cursor: pointer;
	}
}