#navigation
{
	width: rem-calc(304);
	height: 100%;
	background: $med-blue;
	position: fixed;
	left: 0;
	z-index: 2;

	.main__navigation__block,
	.secondary__navigation__block
	{
		display: flex;
		flex-direction: column;
		width: rem-calc(64);
		height: 100%;
		padding: rem-calc(24) 0 rem-calc(16);
		position: fixed;
		@extend .align-middle;
	}

	.main__navigation__block
	{
		text-align: center;
		background: darken($med-blue, 2%);

		ul
		{
			list-style: none;
			margin: 0;
			padding: 0;
			flex: 1;

			li
			{
				a
				{
					display: flex;
					width: 40px;
					height: 40px;
					@include border-radius(50%);
					@extend .align-middle;
					@extend .align-center;
					color: $white;
					font-size: rem-calc(18);

					&.active
					{
						background: darken($med-blue, 10%);
					}

					&:hover
					{
						background: darken($med-blue, 20%);
					}
				}

				&:first-child
				{
					margin-bottom: rem-calc(15);

					a
					{
						font-size: rem-calc(26);
					}
				}
			}
		}

		footer
		{
			display: flex;
			flex-direction: column;
			@extend .align-middle;

			a
			{
				display: flex;
				width: 30px;
				height: 30px;
				@include border-radius(50%);
				background: lighten($black, 30%);
				@extend .align-middle;
				@extend .align-center;
				color: $white;
				font-size: rem-calc(20);

				&:hover
				{
					background: lighten($black, 50%);
				}
			}
		}
	}

	.secondary__navigation__block
	{
		width: rem-calc(240);
		left: rem-calc(64);
		background: $med-blue;
		overflow: hidden;

		&.sub
		{
			background: lighten($black, 90%);
		}

		ul
		{
			list-style: none;
			margin: 0;
			padding: 0 rem-calc(15);

			li
			{
				.link
				{
					display: flex;
					width: 100%;
					height: rem-calc(40);
					padding: 0 rem-calc(8) 0 0;
					@extend .align-middle;
					color: $white;

					a
					{
						display: block;
						color: $white;
					}

					.icon
					{
						display: flex;
						width: 50px;
						padding: 0;
						flex-shrink: 0;
						@extend .align-center;
						font-size: rem-calc(20);
					}

					.text
					{
						display: inline-block;
						-webkit-box-flex: 1;
						flex-grow: 1;
						line-height: rem-calc(40);

						.seo
						{
							display: inline-block;
							float: right;
							font-size: rem-calc(11);
							line-height: rem-calc(40);
						}

						span
						{
							float: right;
							font-size: rem-calc(12);
							line-height: rem-calc(40);
						}
					}

					&:hover,
					&.active
					{
						background: darken($med-blue, 10%);
						@include border-radius(3px);
					}
				}

				ul
				{
					display: none;
				}

				&.settings
				{
					ul
					{
						display: block;

						li
						{	
							display: block;

							.link
							{
								color: $black;

								a
								{
									color: $black;
								}

								&:hover,
								&.active
								{
									background: lighten($black, 90%);
									color: lighten($black, 15%);
								}
							}
						}
					}

					.level
					{
						width: 100%;
						height: 100%;
						padding: rem-calc(24) 0 rem-calc(16);
						background: lighten($black, 98%);
						-webkit-transform: translate3d(-100%,0,0);
					    -moz-transform: translate3d(-100%,0,0);
					    transform: translate3d(-100%,0,0);
					    -webkit-transition: all .4s;
					    -moz-transition: all .4s;
					    transition: all .4s;
						position: absolute;
						left: 0;
						top: 0;

						&.open
						{
							-webkit-transform: translate3d(0,0,0);
   							-moz-transform: translate3d(0,0,0);
    						transform: translate3d(0,0,0);
						}

						header
						{
							color: lighten($black, 15%);
						}

						.back
						{
							display: block;
							width: rem-calc(30);
							height: rem-calc(30);
							background: lighten($black, 90%);
							text-align: center;
							line-height: rem-calc(26);
							font-size: rem-calc(16);
							cursor: pointer;
							position: absolute;
							right: 0;
							top: 0;

							span
							{
								margin-right: rem-calc(5);
								font-size: rem-calc(10);
							}
						}
					}
				}
			}
		}

		.secondary__navigation__container
		{
			width: 100%;

			header
			{
				display: flex;
				width: 100%;
				height: 40px;
				margin-bottom: rem-calc(15);
				padding: 0 rem-calc(30);
				@extend .align-middle;
				color: $white;
			}
		}
	}
}