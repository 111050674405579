.button__container
{
	margin-bottom: rem-calc(20);
	text-align: left;
}

.button__large__blue
{
	height: rem-calc(40);
	padding: rem-calc(4) rem-calc(40);
	border: 1px solid $med-blue;
	background-color: $med-blue;
	@include border-radius(4px);
	color: $white;
	line-height: rem-calc(30);
	font-weight: $body-font-weight-bold;
	vertical-align: baseline;
	cursor: pointer;

	&:hover
	{
		background-color: $darker-blue;
		border-color: $darker-blue;
	}
}

.button__inline__block
{
	display: inline-block;
}

.button__block
{
	display: block;
}

.button__normal
{
	height: rem-calc(40);
	padding: rem-calc(4) rem-calc(20);
	text-align: center;
	vertical-align: middle;
	border: 1px solid lighten($black, 85%);
	@include border-radius(5px);
	background: $white;
	color: $light-blue;
	line-height: rem-calc(30);
	vertical-align: baseline;
	cursor: pointer;

	&:hover
	{
		background: lighten($black, 96%);
	}
}

.button__remove
{
	background: $red;
	border-color: $red;
	color: $white;

	&:hover
	{
		background: lighten($red, 10%);
	}
}

.button__restore
{
	background: $yellow;
	border-color: $yellow;
	color: $white;

	&:hover
	{
		background: lighten($yellow, 10%);
	}
}

.button__add
{
	background: $green;
	border-color: $green;
	color: $white;

	&:hover
	{
		background: lighten($green, 10%);
	}
}

.button__upload
{
	display: inline-block;
	margin-top: -1px;
	padding: rem-calc(8) rem-calc(15);
	border: 1px solid lighten($black, 80%);
	@include border-radius(5px);
	@include border-top-radius(0);
	background: $white;
	font-weight: 400;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
	cursor: pointer;

	input[type="file"]
	{
		display: block;
		width: 100%;
		margin: 0;
		padding: 0;
		opacity: 0;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 0;
	}
}

.button__group
{
	display: flex;

	.button__inline__block
	{
		padding: 0 rem-calc(10);
		border: 1px solid lighten($black, 90%);
		background: $white;
		font-size: rem-calc(12);
		line-height: rem-calc(40);
		text-align: center;
		vertical-align: middle;

		&:first-child
		{
			@include border-left-radius(3px);
		}

		&:last-child
		{
			@include border-right-radius(3px);
		}

		&+.button__inline__block
		{
			margin-left: -1px;
		}

		&:hover
		{
			background: lighten($black, 96%);
		}

		span
		{
			margin: 0 rem-calc(5) 0 0;
		}
	}

	span
	{
		margin-left: -1px;

		.button__inline__block
		{
			&:first-child
			{
				@include border-left-radius(0);
			}
		}
	}
}


.button__search
{
	margin-left: -1px;
	padding: 0 rem-calc(10);
	border: 1px solid lighten($black, 90%);
	@include border-right-radius(3px);
	background: $white;
	color: $light-blue;
	font-size: rem-calc(12);
	line-height: rem-calc(40);
	text-align: center;
	vertical-align: middle;
	cursor: pointer;

	&:hover
	{
		background: lighten($black, 96%);
	}
}



.button__float__bar
{
	padding: rem-calc(30) rem-calc(40);
	//border-top: 1px solid lighten($black, 95%);
	background: $white;
	box-shadow: 5px 0px 15px 0 rgba($black, 0.1);
	position: fixed;
	bottom: 0;
	left: rem-calc(304);
	right: rem-calc(16);
	z-index: 50;
}