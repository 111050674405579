@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

$body-font-family: 'Lato', sans-serif;
$body-font-weight-light: 300;
$body-font-weight-normal: 400;
$body-font-weight-bold: 700;
$body-font-weight-black: 900;


h1
{
	font-size: rem-calc(20);
}

h3
{
	font-size: rem-calc(18);
}

@font-face {
	font-family: 'parkhotel';
	src:  url('/fonts/parkhotel.eot?4u4tg6');
	src:  url('/fonts/parkhotel.eot?4u4tg6#iefix') format('embedded-opentype'),
	url('/fonts/parkhotel.ttf?4u4tg6') format('truetype'),
	url('/fonts/parkhotel.woff?4u4tg6') format('woff'),
	url('/fonts/parkhotel.svg?4u4tg6#parkhotel') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'parkhotel' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-stay_flex:before {
	content: "\e90a";
}
.icon-wifi:before {
	content: "\e90b";
}
.icon-room:before {
	content: "\e90c";
}
.icon-side_menu:before {
	content: "\e90d";
}
.icon-right_chev_2:before {
	content: "\e90e";
}
.icon-radial_ticked:before {
	content: "\e90f";
}
.icon-rates:before {
	content: "\e910";
}
.icon-right_chev:before {
	content: "\e911";
}
.icon-pre_approval:before {
	content: "\e912";
}
.icon-radial:before {
	content: "\e913";
}
.icon-locator:before {
	content: "\e914";
}
.icon-dropdown_2:before {
	content: "\e915";
}
.icon-insta:before {
	content: "\e916";
}
.icon-left_chev:before {
	content: "\e917";
}
.icon-dining:before {
	content: "\e918";
}
.icon-dropdown:before {
	content: "\e919";
}
.icon-close:before {
	content: "\e91a";
}
.icon-chevron-down-arrow:before {
	content: "\e909";
}
.icon-next-arrow:before {
	content: "\e907";
}
.icon-prev-arrow:before {
	content: "\e908";
}
.icon-circle-plus:before {
	content: "\e906";
}
.icon-settings:before {
	content: "\e902";
}
.icon-side-calendar:before {
	content: "\e903";
}
.icon-calendar:before {
	content: "\e904";
}
.icon-rocket:before {
	content: "\e905";
}
.icon-campaigns:before {
	content: "\e901";
}
.icon-overview:before {
	content: "\e900";
}