.admin__grid {

    display: flex;
    flex-wrap: wrap;

    > .row {
        margin:    0 -15px;
        max-width: none;
    }

    .admin__grid--item {
        background:    #ffffff;
        border:        #cccccc;
        margin-bottom: 25px;
        position:      relative;

        menu {
            background: #ffffff;
            margin:     0;
            padding:    0;
            position:   absolute;
            right:      5px;
            top:        5px;
            z-index:    99;

            a, form {
                color:      #000000;
                display:    inline-block;
                font-size:  rem-calc(9);
                padding:    5px 10px;
                text-align: center;

                > a {
                    padding: 0;
                }
            }
        }

        .media {
            //background-size: cover !important;
            //height:          200px;
            img
            {
                width: rem-calc(350);
                height: rem-calc(220);
            }
        }

        .content {
            font-size: rem-calc(11);
            height:    100px;
            padding:   10px;
        }
    }
}